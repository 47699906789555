import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
const SimpleSlider = () => {
  let mainslide = $('.slide--carousel');
  let mainslideSP = $('.slide--carousel__SP');
  mainslide.slick({
    'autoplay': true,
    'arrows': false,
    "dots": true,
    "pauseOnHover": false,
    "fade": true,
    "speed": 500,
    "infinite": true,
    "cssEase": 'ease-in-out',
    "touchThreshold": 100
  });  
  mainslideSP.slick({
    'autoplay': true,
    'arrows': false,
    "dots": true,
    "pauseOnHover": false,
    "fade": true,
    "speed": 500,
    "infinite": true,
    "cssEase": 'ease-in-out',
    "touchThreshold": 100
  });
}
export { SimpleSlider };