import ScrollReveal from 'scrollreveal';
import { SimpleSlider } from './components/top.js';

const pagetoTop = () => {
  let pageTop = $('#back-top');
  pageTop.on('click', function () {
    $('body,html').animate({
      scrollTop: 0
    }, 500);
    return false;
  });
  pageTop.css('display', 'none');
  $(window).on('scroll', function () {
    if ($(this).scrollTop() > 600) {
      pageTop.fadeIn();
      var footerHeight = $('.footer').outerHeight();
      if ($(this).scrollTop() >= $('.footer').offset().top - window.innerHeight + footerHeight) {
        pageTop.css('bottom', footerHeight - 30);
      }
      else {
        pageTop.css('bottom', 30);
      }
    } else {
      pageTop.fadeOut();
    }
  });
}


const initMobileMenu = () => {
  $('.menu-btn').on('click', function () {
    $('html').toggleClass('mn-open');
    $('.overlay-header').on('click', function () {
      $('html').removeClass('mn-open');
    });
  });
}
const animation = () => {

  window.addEventListener("load", function () {
    const images = document.querySelectorAll("img");
    images.forEach(image => {
      image.classList.add("loaded");
    });
  });
  const sr = ScrollReveal();
  sr.reveal('.section-box:nth-child(2n+1) .section-box__txt', {
    delay: 500,
    duration: 1000,
    origin: 'left',
    distance: '50px',
    easing: 'ease-in-out',
    opacity: 0,
    reset: false
  });
  sr.reveal('.section-box:nth-child(2n+1) .section-box__img', {
    delay: 500,
    duration: 1000,
    origin: 'right',
    distance: '50px',
    easing: 'ease-in-out',
    opacity: 0,
    reset: false
  });
  sr.reveal('.section-box:nth-child(2n) .section-box__txt', {
    delay: 500,
    duration: 1000,
    origin: 'right',
    distance: '50px',
    easing: 'ease-in-out',
    opacity: 0,
    reset: false
  });
  sr.reveal('.section-box:nth-child(2n) .section-box__img', {
    delay: 500,
    duration: 1000,
    origin: 'left',
    distance: '50px',
    easing: 'ease-in-out',
    opacity: 0,
    reset: false
  });
  sr.reveal('.contact img, .video-inner > a img', {
    delay: 500,
    duration: 1000,
    origin: 'bottom',
    distance: '50px',
    easing: 'ease',
    opacity: 0,
    reset: false
  });

}

const Toanchor = () => {
  $('a').on('click', function () {
    let href = $(this).attr("href");
    if (href.indexOf('#') >= 0) {
      href = href.substr(href.indexOf('#'));
      let target = $(href == "#" || href == "" ? 'body' : href);
      const headerHight = $('.header').outerHeight();
      let pos = target.offset().top - headerHight - 100;
      $("html, body").animate({ scrollTop: pos }, 300, "swing");
    }
  });
}

$(function () {
  SimpleSlider();
  pagetoTop();
  initMobileMenu();
  animation();
  Toanchor();

  var iv = null;
  $(window).on('resize', function () {
    if (iv !== null) {
      window.clearTimeout(iv);
    }
    iv = setTimeout(function () {
    }, 50);
  });

});

$(window).on('scroll', function () {
  if ($(window).scrollTop() >= 50) {
    $('.header').addClass('--fixed');
  }
  else {
    $('.header').removeClass('--fixed');
  }
});
